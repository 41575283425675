
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Multiselect from "vue-multiselect";

import TeamCalculation from "../../models/sporteventCalculation/TeamCalculation";
import TeamCompetitionService from "../../services/sportevent/TeamCompetitionService";
import TeamCompetition from "../../models/sportevent/TeamCompetition";
import TeamCalculationService from "../../services/sporteventCalculation/TeamCalculationService";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import SporteventCompetitionService from "../../services/sportevent/SporteventCompetitionService";

@Component({
  components: {
    Multiselect,
  },
})
export default class TeamCompetitionComponent extends Vue {
  public teamCompetition: TeamCompetition = new TeamCompetition();
  public allTeamCalculations: TeamCalculation[] = [];
  public allSporteventCompetitions: SporteventCompetition[] = [];
  public sporteventCalculationLoaded = false;

  mounted(): void {
    TeamCalculationService.getAll().then((items: TeamCalculation[]) => {
      this.allTeamCalculations = items;
    });

    SporteventCompetitionService.getBySportevent(
      this.$store.state.localstore_sportevent.sportevent.id
    ).then((items: SporteventCompetition[] | undefined) => {
      if (items === undefined) return;
      this.allSporteventCompetitions = items;
      this.sporteventCalculationLoaded = true;
    });

    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      TeamCompetitionService.get(this.$route.params.id).then(
        (item: TeamCompetition) => {
          this.teamCompetition = item;
        }
      );
    } else {
      this.teamCompetition.sportevent.id =
        this.$store.state.localstore_sportevent.sportevent.id;
    }
  }

  save(): void {
    TeamCompetitionService.save(this.teamCompetition).then(
      (item: TeamCompetition) => {
        if (item) {
          ToastService.Success(i18n.tc("labels.saved"), "");
          this.teamCompetition = item;
          this.handleBack();
        }
      }
    );
  }

  getCalculationName(teamCalculation: TeamCalculation): string {
    return teamCalculation.name;
  }

  getSporteventCompetitionName(
    sporteventCompetition: SporteventCompetition
  ): string {
    sporteventCompetition = new SporteventCompetition(sporteventCompetition);
    return sporteventCompetition.competition.name;
  }

  handleBack(): void {
    this.$router.back();
  }
}
